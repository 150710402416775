import React, { memo } from 'react';

const Avatar = ({
  fname,
  lname,
  image,
  backgroundcolour,
  textcolour,
  size,
  fontsize,
  borderradius,
  condition,
  borderColor,
  className
}) => {
  const avatarSize = () => {
    if (size) {
      return size;
    } else {
      return '35';
    }
  };

  const avatarFontSize = () => {
    if (fontsize) {
      return fontsize;
    } else {
      return '20';
    }
  };
  const borderRadius = () => {
    if (borderradius) {
      return borderradius;
    } else {
      return 50;
    }
  };

  const getColors = () => {
    return {
      backgroundColor: backgroundcolour || '#E0CAFF',
      color: textcolour || `#8D5DD1`
    };
  };

  const renderAvatar = () => {
    if (image) {
      return (
        <img
          src={image}
          alt=""
          className={className}
          style={{
            height: `${avatarSize()}px`,
            width: `${avatarSize()}px`,
            borderRadius: `${borderRadius()}%`,
            objectFit: 'cover'
          }}
        />
      );
    } else {
      return (
        <span
          className={className}
          style={{
            height: `${avatarSize()}px`,
            width: `${avatarSize()}px`,
            ...getColors(),
            fontSize: `${avatarFontSize()}px`,
            borderRadius: `${borderRadius()}%`,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontWeight: '600',
            textTransform: 'uppercase',
            fontFamily: 'Albert Sans',
            border: borderColor ? `1px solid ${borderColor}` : ''
          }}>
          {fname ? fname.charAt(0) : ''}
          {lname ? lname.charAt(0) : ''}
          {condition ? condition : ''}
        </span>
      );
    }
  };

  return <>{renderAvatar()}</>;
};
export default memo(Avatar);
